import axios from 'axios';
import K from '../constants';

let baseURL = process.env[K.API_URL];

baseURL = `${baseURL}/api`;

export default axios.create({
	baseURL,
});
